import React, { useState } from 'react';
// import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { ButtonBase, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NavHashLink } from 'react-router-hash-link';
import { useMemo } from 'react';
import {
    ActionContainer,
    DiscussionContainer,
} from '../custom-components/styled-components';
import { SaveCourse } from './SaveCourse';
import { CommentsButton, CourseLikeButton } from '../comments';
import Box from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import profilePicPlaceholder from '../img/profilePicPlaceholder.jpg';

const useStyles = makeStyles()((theme) => ({
    TabContainer: {
        backgroundColor: '#fff',
        width: '100%',
        borderBottom: '0.05rem solid #9ca3af',
        [theme.breakpoints.up('md')]: {
            paddingInline: '1.25rem',
        },
    },
    TabList: {
        padding: '0',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        textAlign: 'center',
        paddingTop: '0.5rem',
        width: '100%',
        overflow: 'auto',
    },
    TabListItem: {
        padding: '0.5rem 0.5rem 0.6rem 0.5rem',
        margin: '0',
        listStyleType: 'none',
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        letterSpacing: '0.025em',
        // color: props.isActive ? '#ea7112' : '#000',
        borderBottomWidth: '0.2rem',
        borderBottomStyle: 'solid',
        // borderBottomColor: props.isActive ? '#ea7112' : 'transparent',
        fallbacks: [
            {
                padding: '0',
            },
        ],
        fontWeight: '500',
        width: 'max-content',
    },
}));

const CourseTabs = (props) => {
    const { classes } = useStyles();
    const { userDetails, course = {}, wpCourseData } = props;
    // console.log(course);
    // console.log(wpCourseData);
    const { courseSlug } = useParams();
    const [tabs, setTabs] = useState([
        {
            id: 1,
            tabName: 'Overview',
            isActive: true,
            href: '#overview',
        },
        {
            id: 2,
            tabName: 'Curriculum',
            isActive: false,
            href: '#curriculum',
        },
        {
            id: 3,
            tabName: 'Instructor(s)',
            isActive: false,
            href: '#instructor',
        },
        /*{
            id: 4,
            tabName: 'Relevant Job Roles',
            isActive: false,
            href: '#relevant-job-roles',
        },*/
        {
            id: 5,
            tabName: 'Related Courses',
            isActive: false,
            href: '#related-courses',
        },
        {
            id: 6,
            tabName: 'Pricing',
            isActive: false,
            href: '#pricing',
        },
        {
            id: 7,
            tabName: 'FAQs',
            isActive: false,
            href: '#faq',
        },
    ]);

    const [menuItems] = useState([
        {
            id: 1,
            name: 'Skills You Will Gain​',
            href: '#skills-you-gain',
        },
        {
            id: 2,
            name: 'Learning Outcomes',
            href: '#learning-outcomes',
        },
        {
            id: 3,
            name: 'Prerequisites',
            href: '#prerequisites',
        },
    ]);
    const [anchorEl, setAnchorEl] = useState(null);

    const memoizedTabs = useMemo(() => {
        if (userDetails) {
            return tabs.filter((tab) => tab.tabName !== 'Pricing');
        } else {
            return tabs;
        }
    }, [tabs, userDetails]);

    const mentionables = useMemo(() => {
        return (
            wpCourseData?.instructors
                .filter((instructor) => instructor)
                .filter(({ email }) => userDetails?.user_email !== email)
                .map(
                    ({
                        instructor,
                        instructorSlug,
                        email,
                        instructorPic,
                        instructorPicNoBackground,
                    }) => ({
                        id: instructorSlug,
                        name: instructor,
                        _name: instructor,
                        email,
                        profilePicUrl:
                            instructorPic ||
                            instructorPicNoBackground ||
                            profilePicPlaceholder,
                    })
                ) || []
        );
    }, [wpCourseData, userDetails]);

    const handleClick = (tabId) => {
        setTabs((prev) => {
            return prev.map((tab) => {
                if (tab.id === tabId) {
                    return { ...tab, isActive: true };
                } else {
                    return { ...tab, isActive: false };
                }
            });
        });
    };

    const handleMenuClick = (event, tabId) => {
        setAnchorEl(event.currentTarget);
        handleClick(tabId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleScroll = (el) => {
        const rect = el.getBoundingClientRect();
        const offset = -60;
        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
        const top = rect.top + scrollTop + offset;
        window.scrollTo({
            top: top,
            behavior: 'smooth',
        });
    };

    return (
        <div className={classes.TabContainer}>
            <ul className={classes.TabList}>
                {memoizedTabs.map((tab) => {
                    if (tab.id === 1) {
                        return (
                            <React.Fragment key={tab.tabName}>
                                <ButtonBase
                                    onClick={(e) => handleMenuClick(e, tab.id)}
                                >
                                    <li
                                        /* isActive={tab.isActive} */
                                        className={classes.TabListItem}
                                        style={{
                                            color: tab.isActive
                                                ? '#ea7112'
                                                : '#000',

                                            borderBottomColor: tab.isActive
                                                ? '#ea7112'
                                                : 'transparent',
                                        }}
                                    >
                                        {tab.tabName}
                                    </li>
                                </ButtonBase>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    {menuItems.map((item) => {
                                        return (
                                            <NavHashLink
                                                to={`/courses/${courseSlug}/${item.href}`}
                                                scroll={(el) =>
                                                    handleScroll(el)
                                                }
                                                key={item.name + item.id}
                                            >
                                                <MenuItem
                                                    onClick={handleMenuClose}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            </NavHashLink>
                                        );
                                    })}
                                </Menu>
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <NavHashLink
                                key={tab.tabName}
                                to={`/courses/${courseSlug}/${tab.href}`}
                                smooth
                                scroll={(el) => handleScroll(el)}
                            >
                                <ButtonBase
                                    onClick={(e) => handleClick(tab.id)}
                                >
                                    <li
                                        // isActive={tab.isActive}
                                        className={classes.TabListItem}
                                        style={{
                                            color: tab.isActive
                                                ? '#ea7112'
                                                : '#000',

                                            borderBottomColor: tab.isActive
                                                ? '#ea7112'
                                                : 'transparent',
                                        }}
                                    >
                                        {tab.tabName}
                                    </li>
                                </ButtonBase>
                            </NavHashLink>
                        );
                    }
                })}

                {/* DISCUSSION CONTAINER */}
                {Object.keys(course).length > 0 && (
                    <DiscussionContainer>
                        <Typography variant="h6" color="inherit">
                            Discussions
                        </Typography>

                        <ActionContainer>
                            <CommentsButton
                                courseId={course.id}
                                courseSlug={course.slug}
                                courseName={course.name}
                                mentionables={mentionables}
                                placeholder="Tag an instructor with @ to notify them..."
                            />

                            <CourseLikeButton
                                courseId={course.id}
                                courseSlug={course.slug}
                                courseName={course.name}
                                defaultLikes={wpCourseData?.baseClaps || 0}
                            />

                            <SaveCourse course={course} user={userDetails} />
                        </ActionContainer>
                    </DiscussionContainer>
                )}
            </ul>
        </div>
    );
};

// const TabContainer = styled.div`
//     background-color: #fff;
//     width: 100%;
//     border-bottom: 0.05rem solid #9ca3af;
//     @media (min-widht: 48em) {
//         padding-inline: 1.25rem;
//     }
// `;

// const TabList = styled.ul`
//     padding: 0;
//     margin: 0;
//     display: flex;
//     align-items: center;
//     gap: 1rem;
//     text-align: center;
//     padding-top: 0.5rem;
//     width: 100%;
//     overflow: auto;
// `;

// const TabListItem = styled.li`
//     padding: 0;
//     margin: 0;
//     list-style-type: none;
//     font-size: 1.125rem; /* 18px */
//     line-height: 1.75rem;
//     letter-spacing: 0.025em;
//     color: ${(props) => (props.isActive ? '#ea7112' : '#000')};
//     border-bottom-width: 0.2rem;
//     border-bottom-style: solid;
//     border-bottom-color: ${(props) =>
//         props.isActive ? '#ea7112' : 'transparent'};
//     /* text-transform: capitalize; */
//     padding: 0.5rem 0.5rem 0.6rem 0.5rem;
//     font-weight: 500;
//     width: max-content;
// `;

export default CourseTabs;
