import { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js';
import {
    Box,
    CircularProgress,
    FormControl,
    Grid,
    Pagination,
    InputLabel,
    Typography,
    Button,
    SearchBox,
    Select,
} from '../ui';
import { FormatListBulletedIcon } from '../icons';
import { useStyles } from './styles';
import { useState } from 'react';
/* import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
    setAllSavedOnDemand,
    getPrivateCourseState,
    setPrivateCourseState,
    getPurchasedCourseState,
} from './Recoil/Selector'; */
import axios from 'axios';
import { useUser } from '../auth/useUser';
// import { EnrolledCourseCard } from './EnrolledCourseCard';
import compareEnrolledCourses from './compareEnrolledCourses';
import { LinearProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import InstructorDetails from './components/InstructorDetails';

const MAX_COURSES_PER_PAGE = 5;

const ENROLLED_COURSES_SORTBY_OPTIONS = [
    {
        label: 'Recently Accessed',
        value: 'RECENT',
    },
    {
        label: 'Title',
        value: 'TITLE',
    },
    {
        label: '% Completed',
        value: 'PERCENTAGE_COMPLTED',
    },
    {
        label: 'Instructor',
        value: 'INSTRUCTOR',
    },
];

const MyContentCourseCard = (props) => {
    const {
        title,
        thumbnail,
        content,
        course,
        handleLaunch,
        hours,
        minutes,
        percentageCompleted,
        instructors,
    } = props;
    const { classes } = useStyles();
    let navigate = useNavigate();

    const percentage = useMemo(() => {
        return Math.round(percentageCompleted * 100 || 0);
    }, [percentageCompleted]);

    return (
        <article
            className={classes.cardContainer}
            onClick={(e) => {
                e.stopPropagation();
                navigate(`/courses/${course.slug}`);
            }}
        >
            {/* CARD IMG CONTAINER */}
            <div className={classes.imgContainer}>
                <img src={thumbnail} height={200} width={300} alt={title} />
            </div>

            {/* CARD CONTENT CONTAINER */}
            <div className={classes.cardContentWrapper}>
                <h2 className={classes.cardTitle}>
                    {ReactHtmlParser(title.replace('(Thinkific)', ''))}
                </h2>
                <Box color="#4e4e4e" fontWeight={600}>
                    {/* <a
                        href={`/channels/${instructorSlug}/on-air`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => e.stopPropagation()}
                        className={classes.instructorSlug}
                    >
                        <span className={classes.instructorName}>
                            {instructor}&nbsp;&#124;&nbsp;
                        </span>
                    </a> */}
                    <span className={classes.instructorName}>
                        {hours && hours !== 0 ? (
                            <>
                                {hours} hour{hours !== 1 && 's'}
                            </>
                        ) : (
                            <></>
                        )}{' '}
                        {minutes && minutes !== 0 ? (
                            <>
                                {minutes} minute{minutes !== 1 && 's'}
                            </>
                        ) : (
                            <></>
                        )}
                    </span>
                </Box>
                <p className={classes.StyledDescription}>{content}</p>

                <div className={classes.cardActions}>
                    {/* INSTRUCTOR DATA ON CARD */}
                    <InstructorDetails instructors={instructors} />

                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={(e) => {
                            handleLaunch(course);
                            e.stopPropagation();
                        }}
                    >
                        Go To Course
                    </Button>
                </div>
            </div>

            {/* CARD PROGRESS CONTAINER */}
            <Box className={classes.cardProgressWrapper} px={1.25}>
                <div className={classes.cardRoot}>
                    <LinearProgress
                        color="secondary"
                        variant="determinate"
                        value={percentage}
                        style={{ height: '100%', borderRadius: '100vmax' }}
                    />
                </div>
                <p className={classes.completePercentage}>
                    <span style={{ fontWeight: '500' }}>{percentage}%</span>{' '}
                    Completed
                </p>
            </Box>
        </article>
    );
};

export const SavedOnDemand = ({ user, savedOnDemand }) => {
    const { classes } = useStyles();

    const [page, setPage] = useState(1);

    const [filteredCourses, setFilteredCourses] = useState(savedOnDemand);

    const [paginatedCourses, setPaginatedCourses] = useState([]);

    // const setPrivateCourse = useSetRecoilState(setPrivateCourseState);
    // const privateCourse = useRecoilValue(getPrivateCourseState);
    // const purchasedCourse = useRecoilValue(getPurchasedCourseState);
    // const setOnDemand = useSetRecoilState(setAllSavedOnDemand);
    // const [open, setOpen] = useState(false);
    // const [selectedCourse, setSelectedCourse] = useState(null);

    useEffect(() => {
        if (savedOnDemand) {
            setFilteredCourses(savedOnDemand);
        }
    }, [savedOnDemand]);

    const totalPages = useMemo(() => {
        if (filteredCourses) {
            return Math.ceil(filteredCourses.length / MAX_COURSES_PER_PAGE);
        } else {
            return 0;
        }
    }, [filteredCourses]);

    useEffect(() => {
        const paginateCourse = (pageNumber) => {
            if (filteredCourses?.length) {
                const start = (pageNumber - 1) * MAX_COURSES_PER_PAGE;
                const end = pageNumber * MAX_COURSES_PER_PAGE;
                const filteredCourse = filteredCourses.slice(start, end);
                setPaginatedCourses(filteredCourse);
            } else {
                setPaginatedCourses([]);
            }
        };

        const timeoutValue = setTimeout(() => {
            paginateCourse(page);
        }, 50);

        return () => {
            clearTimeout(timeoutValue);
        };
    }, [page, filteredCourses]);

    const [searchQuery, setSearchQuery] = useState('');

    const [searchFunc, setSearchFunc] = useState(() => () => []);

    useEffect(() => {
        const fuse = new Fuse(savedOnDemand, {
            keys: ['name', 'description', 'instructor', 'keywords'],
            includeScore: true,
        });
        setSearchFunc(() => {
            return (query) => {
                return query !== ''
                    ? fuse
                          .search(query)
                          .filter(({ score }) => score < 0.5)
                          .map(({ item }) => item)
                    : savedOnDemand;
            };
        });
    }, [savedOnDemand]);

    const handleSearchQueryChange = (e) => {
        if (e.target.value === '') clearSearch();
        else setSearchQuery(e.target.value);
    };

    const search = () => {
        setPage(1);
        setSortBy('');
        setFilteredCourses(searchFunc(searchQuery));
    };

    const clearSearch = () => {
        setPage(1);
        setSearchQuery('');
        setFilteredCourses(savedOnDemand);
    };

    const keyPressHandler = (e) => {
        if (e.key === 'Enter') {
            setPage(1);
            search();
        }
    };

    const [sortBy, setSortBy] = useState('');
    const handleSortByOptionChange = (event) => {
        setSortBy(event.target.value);
        setPage(1);
        setSearchQuery('');
        setFilteredCourses(
            [...savedOnDemand].sort(compareEnrolledCourses(event.target.value))
        );
    };

    // const removeCourse = async (course) => {
    //     try {
    //         const privateRemovedArray = privateCourse.filter(
    //             (item) => item.id !== course.id
    //         );
    //         if (privateRemovedArray.length === privateCourse.length - 1) {
    //             await axios.put(`/api/users/saveUnSaveCourse/${user._id}`, {
    //                 id: course.product_id,
    //                 action: 'unsave',
    //                 course,
    //             });
    //             setPrivateCourse(privateRemovedArray);
    //             return;
    //         }

    //         await axios.put(`/api/users/saveUnSaveCourse/${user._id}`, {
    //             id: course.id,
    //             action: 'unsave',
    //             course,
    //         });
    //         const newSavedOnDemand = savedOnDemand.filter(
    //             (item) => item.id !== course.id
    //         );
    //         setOnDemand(newSavedOnDemand);
    //         console.log('Remove From OnDemand');
    //         setSelectedCourse(null);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const handlePageChange = (event, value) => {
        window.scrollTo(0, 100);
        setPage(value);
    };

    // const handleClickOnCard = (course) => {
    //     setSelectedCourse(course);
    //     setOpen(true);
    // };

    // const onClose = () => {
    //     setOpen(false);
    // };

    // const purchasedCourseCheck = (id) => {
    //     const isCoursePresent = purchasedCourse.filter(
    //         (item) => item.id === id
    //     );
    //     if (isCoursePresent.length > 0) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // };

    const handleLaunch = async (course) => {
        // if (
        //     user.user_subscription_id === '' &&
        //     !purchasedCourseCheck(course.id)
        // ) {
        //     console.log('UPGRADE_PLAN');
        //     return;
        // }
        try {
            console.log(user);
            await axios.post(`/api/users/thinkific/enroll`, {
                courseId: course.productable_id || course.id,
                userId: user.user_thinkific_id,
            });
            const response = await axios.get(
                `/api/sso/${course.slug}/thinkific?clientId=${user.client_id}`,
                {
                    firstName: user.user_firstName,
                    lastName: user.user_lastName,
                }
            );
            const ssoUrl = response.data;
            window.open(ssoUrl, '_blank');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Box>
            {user ? (
                savedOnDemand.length > 0 ? (
                    // false ? (
                    <>
                        <>
                            {/* <Typography
                                style={{ color: '#000' }}
                                className={classes.sectionHeading}
                            >
                                Your courses ({savedOnDemand.length} program
                                {savedOnDemand?.length > 1 ? 's' : ''})
                            </Typography> */}
                            <Box
                                className={classes.searchSortContainer}
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Box className={classes.searchContainer}>
                                    <SearchBox
                                        searchQuery={searchQuery}
                                        search={search}
                                        placeHolder="Search my courses"
                                        handleSearchQueryChange={
                                            handleSearchQueryChange
                                        }
                                        keyPressHandler={keyPressHandler}
                                        clearSearch={clearSearch}
                                    />
                                </Box>
                                <FormControl
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.sort}
                                >
                                    <InputLabel
                                        color="secondary"
                                        style={{ backgroundColor: 'white' }}
                                    >
                                        Sort by
                                    </InputLabel>
                                    <Select
                                        native
                                        value={sortBy}
                                        onChange={handleSortByOptionChange}
                                        //label={sortBy}
                                        color="secondary"
                                    >
                                        <option value="" />
                                        {ENROLLED_COURSES_SORTBY_OPTIONS.map(
                                            ({ label, value }) => (
                                                <>
                                                    <option value={value}>
                                                        {label}
                                                    </option>
                                                </>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box
                                className={classes.gridContainer2}
                                // style={{
                                //     width: '100%',
                                // }}
                            >
                                {paginatedCourses.map((course, idx) => (
                                    <Box key={`my-courses-content-${idx}`}>
                                        <MyContentCourseCard
                                            title={course.name}
                                            thumbnail={
                                                course.card_image_url ||
                                                course.course_card_image_url
                                            }
                                            instructors={course.instructors}
                                            content={
                                                course.shortDescription ||
                                                course.description
                                            }
                                            course={course}
                                            handleLaunch={handleLaunch}
                                            hours={course.hours}
                                            minutes={course.minutes}
                                            percentageCompleted={
                                                course.percentage_completed
                                            }
                                        />
                                    </Box>
                                    // <EnrolledCourseCard
                                    //     title={course.name}
                                    //     thumbnail={
                                    //         course.card_image_url ||
                                    //         course.course_card_image_url
                                    //     }
                                    //     instructor={course.instructor}
                                    //     instructorSlug={course.instructorSlug}
                                    //     content={course.description}
                                    //     course={course}
                                    //     handleLaunch={handleLaunch}
                                    //     hours={course.hours}
                                    //     minutes={course.minutes}
                                    //     percentageCompleted={
                                    //         course.percentage_completed
                                    //     }
                                    // />
                                ))}
                            </Box>

                            {paginatedCourses.length && totalPages > 1 ? (
                                <Grid xs={12}>
                                    <Box mt={2} width="fit-content" mx="auto">
                                        <Pagination
                                            variant="outlined"
                                            color="secondary"
                                            count={totalPages}
                                            page={page}
                                            onChange={handlePageChange}
                                        />
                                    </Box>
                                </Grid>
                            ) : null}
                        </>
                        {/* <TopPicks
                            color="#333"
                            data={savedOnDemand}
                            heading="Your courses"
                            showViewAll={false}
                            showActions={true}
                            handleOnClick={handleClickOnCard}
                            handleLaunch={handleLaunch}
                            removeText="Un-Enroll"
                        />
                        {selectedCourse && (
                            <UnEnrollDialog
                                onClose={onClose}
                                handleRemove={removeCourse}
                                open={open}
                                course={selectedCourse}
                            />
                        )} */}
                    </>
                ) : (
                    <Grid
                        container
                        alignItems="center"
                        style={{
                            minHeight: '300px',
                        }}
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                style={{ textAlign: 'center' }}
                            >
                                You don't have any saved courses
                            </Typography>
                            {/* <Typography variant="h3" gutterBottom>
                                <strong>Well, let's fix that!</strong>
                            </Typography> */}
                            {/* <Link to="/courses">
                                <Button variant="outlined" color="secondary">
                                    View courses
                                </Button>
                            </Link> */}
                            <Link to="/browse" className={classes.browseLink}>
                                <Button
                                    variant="contained"
                                    startIcon={<FormatListBulletedIcon />}
                                >
                                    Browse
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                )
            ) : (
                <Box className={classes.load}>
                    <CircularProgress color="secondary" />
                </Box>
            )}
        </Box>
    );
};

// const StyledDescription = styled.p`
//     height: 5rem;
//     overflow: hidden;
//     position: relative;
//     &::after {
//         content: '""';
//         width: 100%;
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         height: 2.5rem;
//         background-image: linear-gradient(
//             to bottom,
//             rgba(250, 250, 250, 0),
//             rgba(250, 250, 250, 0.9) 90%
//         );
//         z-index: 1;
//         pointer-events: none;
//     }
// `;
