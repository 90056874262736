// import { VideoList } from './VideoList';
// import { CoursesPage } from './CoursesPage';
import { useEffect, useMemo, useState } from 'react';
import { Box, Grid, NavigationTab } from '../ui';
import { useStyles } from './styles';
import { useParams } from 'react-router-dom';
// import { Banner } from './Banner.js';
import { SavedJourneys } from './SavedJourneys';
import { SavedOnDemand } from './SavedOnDemand';
import { JOURNEY, CERTIFICATION } from '../util/journeyType';
import { dashboardRoute as route } from '../seo/routeMetaData';
import { HelmetWrapper } from '../seo';
import { SavedOnAirRsvps } from '../../src/my-account/SavedOnAirRsvps';
import DashboardPrivateJourneys from './DashboardPrivateJourneys';
import DashboardPrivateCourses from './DashboardPrivateCourses';
import FirstTimeUser from './FirstTimeUser';
import { useIsAdmin } from '../admin/useIsAdmin';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import Chat from '../chat';

export const Content = ({
    // section,
    socket,
    user,
    savedCourses,
    enrolledAndPurchasedOnDemand,
    privateOnDemand,
    userOnAir,
}) => {
    const { subSection: section } = useParams();
    const { client, isAdmin } = useIsAdmin();
    const theme = useTheme();
    const isLargeDevice = useMediaQuery(theme.breakpoints.up('sm'));
    // const privateCourse = useGetPrivateClasses(user);
    // const {
    //     purchasedCourses,
    //     purchasedOnDemand,
    //     purchasedCertifications,
    // } = useGetPurchasedCourse();
    // const { savedCourses } = useGetSaved(user);
    // GET THE ON-DEMAND COURSES
    // const tags = 'react'; //TAGS CAN BE CHANGED LATER
    // const topCourses = useGetTopCourses({ user, tags });
    // const onDemandHeading = (
    //     <>
    //         Here are some top picks for you{' '}
    //         <strong>{user && user.user_firstName}</strong>
    //     </>
    // );

    // const { enrolledAndPurchasedOnDemand } = useEnrolledAndPurchasedOnDemand(
    //     user
    // );

    // GET USER SAVED ON DEMANDS, AND SAVED CERTIFICATIONS
    //const { savedOnDemand, savedCertification } = useGetSavedOnDemand(user);

    // const userOnAir = useGetSavedRsvps(user);
    const { classes } = useStyles();

    // var [courseHours, setCourseHours] = useState(() => new Map());
    // var [courseMin, setCourseMin] = useState(() => new Map());
    // var [domain, setDomain] = useState(() => new Map());

    // const userEmailDomain = user ? user.user_email.split('@').pop() : null;

    // const getCourseDomainDetail = async () => {
    //     const res = await axios.get(
    //         `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/catalogue/lengths?nowprocket=1`
    //     );
    //     setCourseHours(
    //         new Map(res.data.map((key) => [key.thinkificCourseId, key.hours]))
    //     );
    //     setCourseMin(
    //         new Map(res.data.map((key) => [key.thinkificCourseId, key.minutes]))
    //     );
    //     setDomain(
    //         new Map(res.data.map((key) => [key.thinkificCourseId, key.domain]))
    //     );
    // };
    // useEffect(() => {
    //     getCourseDomainDetail();
    // }, []);

    const tabValues = useMemo(
        () => {
            // if (!user?.user_logged_in_once) {
            //     return [
            //         {
            //             name: 'First Time User',
            //             slug: 'first-time-user',
            //             component: <FirstTimeUser />,
            //         },
            //         // {
            //         //     name: `Recommendations`,
            //         //     slug: 'recommendations',
            //         //     component: courseMin && courseHours && (
            //         //         <RecommendedOnDemand
            //         //             allAccessibleCourse={enrolledAndPurchasedOnDemand || []}
            //         //             user={user}
            //         //             minutes={courseMin}
            //         //             hours={courseHours}
            //         //             domainname={domain}
            //         //             setUser={setUser}
            //         //         />
            //         //     ),
            //         //     //hideTab: user?.user_current_plan !== astronaut_id,
            //         // },

            //         // {
            //         //     name: `SAVED/RSVPS`,
            //         //     slug: 'saved-rsvps',
            //         //     component: <SavedCourses savedCourse={savedCourses} user={user} />,
            //         // },
            //         {
            //             name: 'MY COURSES',
            //             slug: 'my-courses',
            //             component: (
            //                 <SavedOnDemand
            //                     user={user}
            //                     savedOnDemand={enrolledAndPurchasedOnDemand || []}
            //                     showViewAll={false}
            //                 />
            //             ),
            //         },
            //         {
            //             name: 'MY Certifications',
            //             slug: 'certifications',
            //             component: (
            //                 // <SavedCertifications
            //                 //     savedCertification={[
            //                 //         ...savedCertification,
            //                 //         ...purchasedCertifications,
            //                 //     ]}
            //                 //     user={user}
            //                 //     />
            //                 <SavedJourneys
            //                     user={user}
            //                     displayCertification={true}
            //                     sectionType={CERTIFICATION}
            //                 />
            //             ),
            //         },
            //         {
            //             name: 'MY Journeys',
            //             slug: 'journeys',
            //             component: (
            //                 <SavedJourneys
            //                     user={user}
            //                     displayCertification={false}
            //                     sectionType={JOURNEY}
            //                 />
            //             ),
            //             //hideTab: true,
            //         },
            //         {
            //             name: 'PRIVATE COURSES',
            //             slug: 'private-course',
            //             // component: <DashboardPrivateCourses user={user} />,
            //             component: (
            //                 <SavedOnDemand
            //                     user={user}
            //                     savedOnDemand={privateOnDemand || []}
            //                     showViewAll={false}
            //                 />
            //             ),
            //         },
            //         {
            //             name: 'Private Journeys',
            //             slug: 'private-journeys',
            //             component: <DashboardPrivateJourneys />,
            //             //hideTab: true,
            //         },
            //         // {
            //         //     name: 'View On Air',
            //         //     slug: 'on-air',
            //         //     component: <SavedOnAir user={user} />,
            //         // },

            //         // {
            //         //     name: 'Messaging',
            //         //     slug: 'messages',
            //         //     component: (
            //         //         <Chat
            //         //             socket={socket}
            //         //             // user={user}
            //         //             // isUserLoading={isUserLoading}
            //         //         />
            //         //     ),
            //         //     hideTab: false,
            //         // },
            //         // {
            //         //     name: 'Leaderboards',
            //         //     slug: 'leaderboard',
            //         //     component: <Leaderboard />,
            //         //     //hideTab: true,
            //         // },
            //         // {
            //         //     name: 'PRIVATE COURSES',
            //         //     slug: 'private-courses',
            //         //     component: (
            //         //         <SavedOnDemand
            //         //             user={user}
            //         //             savedOnDemand={[...privateCourse]}
            //         //             showViewAll={false}
            //         //         />
            //         //     ),
            //         //     hideTab: !Object.keys(
            //         //         privateEmailDomainsAndBundleNames
            //         //     ).includes(userEmailDomain),
            //         // },
            //         {
            //             name: `SAVED/RSVPs`,
            //             slug: 'saved-register',
            //             component: (
            //                 <SavedOnAirRsvps
            //                     user={user}
            //                     data={userOnAir}
            //                     savedCourse={savedCourses}
            //                 />
            //             ),
            //         },
            //     ];
            // } else {
            return [
                {
                    name: 'MY COURSES',
                    slug: 'my-courses',
                    component: (
                        <SavedOnDemand
                            user={user}
                            savedOnDemand={enrolledAndPurchasedOnDemand || []}
                            showViewAll={false}
                        />
                    ),
                },
                // {
                //     name: 'MY Certifications',
                //     slug: 'certifications',
                //     component: (
                //         // <SavedCertifications
                //         //     savedCertification={[
                //         //         ...savedCertification,
                //         //         ...purchasedCertifications,
                //         //     ]}
                //         //     user={user}
                //         //     />
                //         <SavedJourneys
                //             user={user}
                //             displayCertification={true}
                //             sectionType={CERTIFICATION}
                //         />
                //     ),
                // },
                // {
                //     name: 'MY Journeys',
                //     slug: 'journeys',
                //     component: (
                //         <SavedJourneys
                //             user={user}
                //             displayCertification={false}
                //             sectionType={JOURNEY}
                //         />
                //     ),
                //     //hideTab: true,
                // },
                // {
                //     name: 'First Time User',
                //     slug: 'first-time-user',
                //     component: <FirstTimeUser />,
                // },
                // {
                //     name: `Recommendations`,
                //     slug: 'recommendations',
                //     component: courseMin && courseHours && (
                //         <RecommendedOnDemand
                //             allAccessibleCourse={enrolledAndPurchasedOnDemand || []}
                //             user={user}
                //             minutes={courseMin}
                //             hours={courseHours}
                //             domainname={domain}
                //             setUser={setUser}
                //         />
                //     ),
                //     //hideTab: user?.user_current_plan !== astronaut_id,
                // },

                // {
                //     name: `SAVED/RSVPS`,
                //     slug: 'saved-rsvps',
                //     component: <SavedCourses savedCourse={savedCourses} user={user} />,
                // },
                {
                    name: 'PRIVATE COURSES',
                    slug: 'private-course',
                    // component: <DashboardPrivateCourses user={user} />,
                    component: (
                        <SavedOnDemand
                            user={user}
                            savedOnDemand={privateOnDemand || []}
                            showViewAll={false}
                        />
                    ),
                },
                // {
                //     name: 'Private Journeys',
                //     slug: 'private-journeys',
                //     component: <DashboardPrivateJourneys />,
                //     //hideTab: true,
                // },
                // {
                //     name: 'View On Air',
                //     slug: 'on-air',
                //     component: <SavedOnAir user={user} />,
                // },

                // {
                //     name: 'Messaging',
                //     slug: 'messages',
                //     component: (
                //         <Chat
                //             socket={socket}
                //             // user={user}
                //             // isUserLoading={isUserLoading}
                //         />
                //     ),
                //     hideTab: false,
                // },
                // {
                //     name: 'Leaderboards',
                //     slug: 'leaderboard',
                //     component: <Leaderboard />,
                //     //hideTab: true,
                // },
                // {
                //     name: 'PRIVATE COURSES',
                //     slug: 'private-courses',
                //     component: (
                //         <SavedOnDemand
                //             user={user}
                //             savedOnDemand={[...privateCourse]}
                //             showViewAll={false}
                //         />
                //     ),
                //     hideTab: !Object.keys(
                //         privateEmailDomainsAndBundleNames
                //     ).includes(userEmailDomain),
                // },
                // {
                //     name: `SAVED/RSVPs`,
                //     slug: 'saved-register',
                //     component: (
                //         <SavedOnAirRsvps
                //             user={user}
                //             data={userOnAir}
                //             savedCourse={savedCourses}
                //         />
                //     ),
                // },
            ];
        },

        // if (
        //     Object.keys(client).length === 0 &&
        //     !isAdmin &&
        //     !user?.is_instructor &&
        //     !user?.user_logged_in_once
        // ) {
        //     return [
        //         {
        //             name: 'First Time User',
        //             slug: 'first-time-user',
        //             component: <FirstTimeUser />,
        //         },
        //         // {
        //         //     name: `Recommendations`,
        //         //     slug: 'recommendations',
        //         //     component: courseMin && courseHours && (
        //         //         <RecommendedOnDemand
        //         //             allAccessibleCourse={enrolledAndPurchasedOnDemand || []}
        //         //             user={user}
        //         //             minutes={courseMin}
        //         //             hours={courseHours}
        //         //             domainname={domain}
        //         //             setUser={setUser}
        //         //         />
        //         //     ),
        //         //     //hideTab: user?.user_current_plan !== astronaut_id,
        //         // },

        //         // {
        //         //     name: `SAVED/RSVPS`,
        //         //     slug: 'saved-rsvps',
        //         //     component: <SavedCourses savedCourse={savedCourses} user={user} />,
        //         // },
        //         {
        //             name: 'MY COURSES',
        //             slug: 'my-courses',
        //             component: (
        //                 <SavedOnDemand
        //                     user={user}
        //                     savedOnDemand={enrolledAndPurchasedOnDemand || []}
        //                     showViewAll={false}
        //                 />
        //             ),
        //         },
        //         {
        //             name: 'MY Certifications',
        //             slug: 'certifications',
        //             component: (
        //                 // <SavedCertifications
        //                 //     savedCertification={[
        //                 //         ...savedCertification,
        //                 //         ...purchasedCertifications,
        //                 //     ]}
        //                 //     user={user}
        //                 //     />
        //                 <SavedJourneys
        //                     user={user}
        //                     displayCertification={true}
        //                     sectionType={CERTIFICATION}
        //                 />
        //             ),
        //         },
        //         {
        //             name: 'MY Journeys',
        //             slug: 'journeys',
        //             component: (
        //                 <SavedJourneys
        //                     user={user}
        //                     displayCertification={false}
        //                     sectionType={JOURNEY}
        //                 />
        //             ),
        //             //hideTab: true,
        //         },
        //         {
        //             name: 'PRIVATE COURSES',
        //             slug: 'private-course',
        //             // component: <DashboardPrivateCourses user={user} />,
        //             component: (
        //                 <SavedOnDemand
        //                     user={user}
        //                     savedOnDemand={privateOnDemand || []}
        //                     showViewAll={false}
        //                 />
        //             ),
        //         },
        //         {
        //             name: 'Private Journeys',
        //             slug: 'private-journeys',
        //             component: <DashboardPrivateJourneys />,
        //             //hideTab: true,
        //         },
        //         // {
        //         //     name: 'View On Air',
        //         //     slug: 'on-air',
        //         //     component: <SavedOnAir user={user} />,
        //         // },

        //         // {
        //         //     name: 'Messaging',
        //         //     slug: 'messages',
        //         //     component: (
        //         //         <Chat
        //         //             socket={socket}
        //         //             // user={user}
        //         //             // isUserLoading={isUserLoading}
        //         //         />
        //         //     ),
        //         //     hideTab: false,
        //         // },
        //         // {
        //         //     name: 'Leaderboards',
        //         //     slug: 'leaderboard',
        //         //     component: <Leaderboard />,
        //         //     //hideTab: true,
        //         // },
        //         // {
        //         //     name: 'PRIVATE COURSES',
        //         //     slug: 'private-courses',
        //         //     component: (
        //         //         <SavedOnDemand
        //         //             user={user}
        //         //             savedOnDemand={[...privateCourse]}
        //         //             showViewAll={false}
        //         //         />
        //         //     ),
        //         //     hideTab: !Object.keys(
        //         //         privateEmailDomainsAndBundleNames
        //         //     ).includes(userEmailDomain),
        //         // },
        //         {
        //             name: `SAVED/RSVPs`,
        //             slug: 'saved-register',
        //             component: (
        //                 <SavedOnAirRsvps
        //                     user={user}
        //                     data={userOnAir}
        //                     savedCourse={savedCourses}
        //                 />
        //             ),
        //         },
        //     ];
        // } else if (Object.keys(client).length > 0 /* && !isAdmin */) {
        //     return [
        //         // {
        //         //     name: `Recommendations`,
        //         //     slug: 'recommendations',
        //         //     component: courseMin && courseHours && (
        //         //         <RecommendedOnDemand
        //         //             allAccessibleCourse={enrolledAndPurchasedOnDemand || []}
        //         //             user={user}
        //         //             minutes={courseMin}
        //         //             hours={courseHours}
        //         //             domainname={domain}
        //         //             setUser={setUser}
        //         //         />
        //         //     ),
        //         //     //hideTab: user?.user_current_plan !== astronaut_id,
        //         // },
        //         {
        //             name: 'First Time User',
        //             slug: 'first-time-user',
        //             component: <FirstTimeUser />,
        //         },
        //         {
        //             name: 'PRIVATE COURSES',
        //             slug: 'private-course',
        //             // component: <DashboardPrivateCourses user={user} />,
        //             component: (
        //                 <SavedOnDemand
        //                     user={user}
        //                     savedOnDemand={privateOnDemand || []}
        //                     showViewAll={false}
        //                 />
        //             ),
        //         },
        //         {
        //             name: 'Private Journeys',
        //             slug: 'private-journeys',
        //             component: <DashboardPrivateJourneys />,
        //             //hideTab: true,
        //         },
        //         {
        //             name: `SAVED/RSVPs`,
        //             slug: 'saved-register',
        //             component: (
        //                 <SavedOnAirRsvps
        //                     user={user}
        //                     data={userOnAir}
        //                     savedCourse={savedCourses}
        //                 />
        //             ),
        //         },

        //         // {
        //         //     name: `SAVED/RSVPS`,
        //         //     slug: 'saved-rsvps',
        //         //     component: <SavedCourses savedCourse={savedCourses} user={user} />,
        //         // },
        //         {
        //             name: 'MY COURSES',
        //             slug: 'my-courses',
        //             component: (
        //                 <SavedOnDemand
        //                     user={user}
        //                     savedOnDemand={enrolledAndPurchasedOnDemand || []}
        //                     showViewAll={false}
        //                 />
        //             ),
        //         },
        //         {
        //             name: 'MY Certifications',
        //             slug: 'certifications',
        //             component: (
        //                 // <SavedCertifications
        //                 //     savedCertification={[
        //                 //         ...savedCertification,
        //                 //         ...purchasedCertifications,
        //                 //     ]}
        //                 //     user={user}
        //                 //     />
        //                 <SavedJourneys
        //                     user={user}
        //                     displayCertification={true}
        //                     sectionType={CERTIFICATION}
        //                 />
        //             ),
        //         },
        //         {
        //             name: 'MY Journeys',
        //             slug: 'journeys',
        //             component: (
        //                 <SavedJourneys
        //                     user={user}
        //                     displayCertification={false}
        //                     sectionType={JOURNEY}
        //                 />
        //             ),
        //             //hideTab: true,
        //         },
        //         // {
        //         //     name: 'View On Air',
        //         //     slug: 'on-air',
        //         //     component: <SavedOnAir user={user} />,
        //         // },

        //         // {
        //         //     name: 'Messaging',
        //         //     slug: 'messages',
        //         //     component: (
        //         //         <Chat
        //         //             socket={socket}
        //         //             // user={user}
        //         //             // isUserLoading={isUserLoading}
        //         //         />
        //         //     ),
        //         //     hideTab: false,
        //         // },
        //         // {
        //         //     name: 'Leaderboards',
        //         //     slug: 'leaderboard',
        //         //     component: <Leaderboard />,
        //         //     //hideTab: true,
        //         // },
        //         // {
        //         //     name: 'PRIVATE COURSES',
        //         //     slug: 'private-courses',
        //         //     component: (
        //         //         <SavedOnDemand
        //         //             user={user}
        //         //             savedOnDemand={[...privateCourse]}
        //         //             showViewAll={false}
        //         //         />
        //         //     ),
        //         //     hideTab: !Object.keys(
        //         //         privateEmailDomainsAndBundleNames
        //         //     ).includes(userEmailDomain),
        //         // },
        //     ];
        // } else {
        //     return [
        //         // {
        //         //     name: `Recommendations`,
        //         //     slug: 'recommendations',
        //         //     component: courseMin && courseHours && (
        //         //         <RecommendedOnDemand
        //         //             allAccessibleCourse={enrolledAndPurchasedOnDemand || []}
        //         //             user={user}
        //         //             minutes={courseMin}
        //         //             hours={courseHours}
        //         //             domainname={domain}
        //         //             setUser={setUser}
        //         //         />
        //         //     ),
        //         //     //hideTab: user?.user_current_plan !== astronaut_id,
        //         // },

        //         {
        //             name: `SAVED/RSVPs`,
        //             slug: 'saved-register',
        //             component: (
        //                 <SavedOnAirRsvps
        //                     user={user}
        //                     data={userOnAir}
        //                     savedCourse={savedCourses}
        //                 />
        //             ),
        //         },

        //         // {
        //         //     name: `SAVED/RSVPS`,
        //         //     slug: 'saved-rsvps',
        //         //     component: <SavedCourses savedCourse={savedCourses} user={user} />,
        //         // },
        //         {
        //             name: 'MY COURSES',
        //             slug: 'my-courses',
        //             component: (
        //                 <SavedOnDemand
        //                     user={user}
        //                     savedOnDemand={enrolledAndPurchasedOnDemand || []}
        //                     showViewAll={false}
        //                 />
        //             ),
        //         },
        //         {
        //             name: 'MY Certifications',
        //             slug: 'certifications',
        //             component: (
        //                 // <SavedCertifications
        //                 //     savedCertification={[
        //                 //         ...savedCertification,
        //                 //         ...purchasedCertifications,
        //                 //     ]}
        //                 //     user={user}
        //                 //     />
        //                 <SavedJourneys
        //                     user={user}
        //                     displayCertification={true}
        //                     sectionType={CERTIFICATION}
        //                 />
        //             ),
        //         },
        //         {
        //             name: 'MY Journeys',
        //             slug: 'journeys',
        //             component: (
        //                 <SavedJourneys
        //                     user={user}
        //                     displayCertification={false}
        //                     sectionType={JOURNEY}
        //                 />
        //             ),
        //             //hideTab: true,
        //         },
        //         {
        //             name: 'PRIVATE COURSES',
        //             slug: 'private-course',
        //             // component: <DashboardPrivateCourses user={user} />,
        //             component: (
        //                 <SavedOnDemand
        //                     user={user}
        //                     savedOnDemand={privateOnDemand || []}
        //                     showViewAll={false}
        //                 />
        //             ),
        //         },
        //         {
        //             name: 'Private Journeys',
        //             slug: 'private-journeys',
        //             component: <DashboardPrivateJourneys />,
        //             //hideTab: true,
        //         },
        //         {
        //             name: 'First Time User',
        //             slug: 'first-time-user',
        //             component: <FirstTimeUser />,
        //         },
        //         // {
        //         //     name: 'View On Air',
        //         //     slug: 'on-air',
        //         //     component: <SavedOnAir user={user} />,
        //         // },

        //         // {
        //         //     name: 'Messaging',
        //         //     slug: 'messages',
        //         //     component: (
        //         //         <Chat
        //         //             socket={socket}
        //         //             // user={user}
        //         //             // isUserLoading={isUserLoading}
        //         //         />
        //         //     ),
        //         //     hideTab: false,
        //         // },
        //         // {
        //         //     name: 'Leaderboards',
        //         //     slug: 'leaderboard',
        //         //     component: <Leaderboard />,
        //         //     //hideTab: true,
        //         // },
        //         // {
        //         //     name: 'PRIVATE COURSES',
        //         //     slug: 'private-courses',
        //         //     component: (
        //         //         <SavedOnDemand
        //         //             user={user}
        //         //             savedOnDemand={[...privateCourse]}
        //         //             showViewAll={false}
        //         //         />
        //         //     ),
        //         //     hideTab: !Object.keys(
        //         //         privateEmailDomainsAndBundleNames
        //         //     ).includes(userEmailDomain),
        //         // },
        //     ];
        // }
        // }, [
        //     client,
        //     enrolledAndPurchasedOnDemand,
        //     privateOnDemand,
        //     isAdmin,
        //     savedCourses,
        //     user,
        //     userOnAir,
        // ]);
        // }

        [
            enrolledAndPurchasedOnDemand,
            privateOnDemand,
            savedCourses,
            user,
            userOnAir,
        ]
    );

    const initialTabValue = section
        ? tabValues.findIndex(({ slug }) => slug === section)
        : 0;
    // VALUES USED FOR NAVIGATION
    const [value, setValue] = useState(initialTabValue);

    useEffect(() => {
        console.log('setValue');
        setValue(
            section ? tabValues.findIndex(({ slug }) => slug === section) : 0
        );
    }, [section, tabValues]);

    const handleOnSave = () => {
        console.log(1);
    };

    return (
        <>
            <HelmetWrapper route={route} />
            {user && (
                <Box
                    style={{
                        width: '90%',
                        margin: 'auto',
                    }}
                >
                    {/* <BackFloatButton /> */}
                    {/* <HeadingSection
                        heading="My Dashboard"
                        subHeading={
                            'Track your recommendations, activities, progress and achievements'
                        }
                        iconComponent={<SpeedIcon fontSize="large" />}
                    /> */}
                    <Grid container direction="row" spacing={6}>
                        <Grid item xs={12} sm={4} md={2} lg={3} xl={2}>
                            <Box /* className={classes.centerTabs} */>
                                <NavigationTab
                                    collections={tabValues}
                                    value={value}
                                    setValue={setValue}
                                    basePath="/profile/dashboard/content"
                                    vertical={isLargeDevice}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={10} lg={7} xl={9}>
                            {/* <Container maxWidth="lg"> */}
                            {tabValues[value].component}
                            {/* <SavedCourses user={user} /> */}
                            {/* </Container> */}
                        </Grid>
                    </Grid>
                    {/* DISPLAY ON DEMAND COURSE */}
                    {/* <Grid container className={classes.topPicksCourse}>
                        <Box
                            className={`${classes.picksBg} ${classes.picksBgCourses}`}
                        />
                        {initialTabValue === 0 ? (
                            <Grid item xs={12}>
                                <Container maxWidth="lg">
                                    <TopPicks
                                        data={topCourses}
                                        user={user}
                                        heading={onDemandHeading}
                                        handleOnSave={handleOnSave}
                                    />
                                </Container>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Grid> */}
                    {/* DISPLAY ON AIR COURSE */}
                    {/* <Grid container className={classes.topPicksCourse}>
                        <Box
                            className={`${classes.picksBgOnAir} ${classes.picksBg}`}
                        />
                        <Grid item xs={12}>
                            <Container maxWidth="lg">
                                <OnAirCarousel
                                    data={onAirRecommend}
                                    user={user}
                                    linkPath="/on-air"
                                />
                            </Container>
                        </Grid>
                    </Grid> */}
                </Box>
            )}
        </>
    );
};
